


import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { TCountry } from '@/_types/country.type';
import { TCountryState } from '@/_types/country-state.type';
import { TCity } from '@/_types/city.type';
import { DirectiveBinding } from 'vue/types/options';
import _cloneDeep from 'lodash.clonedeep';
import UtilsHelper from '@/_helpers/utils.helper';

const USA_COUNTRY_ID_EW = 233;
const AUSTRALIA_COUNTRY_ID_EW = 14;
const FOUND_CITIES_AMOUNT = 20;

export type TEventLocationChoicePayload = {
  country: TCountry;
  city: TCity;
}

@Component({
  directives: {
    'click-outside': {
      bind: (el: HTMLElement, binding: DirectiveBinding): void => {
        const ourClickEventHandler = (event: MouseEvent): void => {
          if (!el.contains(event.target as Node) && el !== event.target) {
            // as we are attaching a click event listener to the document (below)
            // ensure the events target is outside the element or a child of it
            binding.value(event); // before binding it
          }
        };
        // attached the handler to the element, so we can remove it later easily
        ((el as unknown) as any).__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener('click', ourClickEventHandler);
      },
      unbind: (el: HTMLElement): void => {
        document.removeEventListener('click', ((el as unknown) as any).__vueClickEventHandler__);
      }
    }
  }
})
export default class EventLocation extends Vue {

  @Ref('cityFiltererInput') cityFiltererInput: HTMLInputElement;
  @Ref('selectCountry') selectCountry: HTMLSelectElement;
  @Getter('locationStore/countries') countries: TCountry[];
  @Getter('locationStore/statesByCountryId') statesByCountryId: (countryId: number) => TCountryState[];
  @Getter('locationStore/citiesByCountryId') citiesByCountryId: (countryId: number) => TCity[];
  @Getter('locationStore/isCountryListLoading') isCountryListLoading: boolean;
  @Getter('locationStore/isStateListLoading') isStateListLoading: boolean;
  @Getter('locationStore/isCityListLoading') isCityListLoading: boolean;
  @Action('locationStore/requestCountries') requestCountries: () => Promise<TCountry[]>;
  @Action('locationStore/requestStates') requestStates: (countryId: number) => Promise<TCountryState[]>;
  @Action('locationStore/requestCities') requestCities: (countryId: number) => Promise<TCity[]>;

  public selectedCountry: TCountry = null;
  public selectedCity: TCity = null;

  public localCityList: TCity[] = []; // N.B.: We need to sort cities, that is why we are cloning store value into a local var
  public isCountrySelectFocused: boolean = false;

  public citySearchString: string = '';
  public isCityFiltererVisible: boolean = false;

  public get selectedCountryId(): number {
    return (this.selectedCountry && this.selectedCountry.id) || 0;
  }

  public get selectedCityId(): number {
    return (this.selectedCity && this.selectedCity.id) || 0;
  }

  public get selectedCityName(): string {
    return (this.selectedCity && this.selectedCity.name) || '';
  }

  public get isUSA(): boolean {
    return this.selectedCountryId === USA_COUNTRY_ID_EW;
  }

  public get isAustralia(): boolean {
    return this.selectedCountryId === AUSTRALIA_COUNTRY_ID_EW;
  }

  public get isStateISO2Needed(): boolean {
    return this.isUSA || this.isAustralia;
  }

  public get foundCities(): TCity[] {
    if (!this.citySearchString) {
      return this.getCitiesForEmptySearch();
    }

    return this.localCityList
      .filter(city => ((city && city.name) || '').toLowerCase().indexOf(this.citySearchString.toLowerCase()) === 0)
      .slice(0, FOUND_CITIES_AMOUNT);
  }

  public get states(): TCountryState[] {
    return this.statesByCountryId(this.selectedCountryId);
  }

  public get cities(): TCity[] {
    return this.citiesByCountryId(this.selectedCountryId);
  }

  public get currentCityText(): string {
    if (!this.selectedCity) {
      return this.$tc('cityFilter.typeCityName');
    }
    return this.getCityItemText(this.selectedCity);
  }

  @Prop(Object)
  public readonly country: TCountry;

  @Prop(Object)
  public readonly city: TCity;

  @Watch('country', { immediate: true })
  private onCountryPropChange(): void {
    this.setLocalVarsFromProps();
  }

  @Watch('city', { immediate: true })
  private onCityPropChange(): void {
    this.selectedCity = this.city ? {...this.city} : null;
  }

  @Watch('cities', { immediate: true })
  private onCitiesChange(): void {
    this.localCityList = _cloneDeep(this.cities);
  }

  @Watch('selectedCountryId', { immediate: true })
  private onSelectedCountryIdChange(): void {
    this.requestData();
  }

  public mounted(): void {
    this.init();
  }

  public async init(): Promise<void> {
    this.setLocalVarsFromProps();
    await this.requestData();
  }

  public setLocalVarsFromProps(): void {
    this.selectedCountry = this.country && this.country.id ? {...this.country} : null;
    this.selectedCity = this.city && this.city.id ? {...this.city} : null;
  }

  public async requestData(): Promise<void> {
    await this.requestCountries();
    await this.requestStates(this.selectedCountryId);
    await this.requestCities(this.selectedCountryId);
  }

  public getCitiesForEmptySearch(): TCity[] {
    if (!this.localCityList.length) {
      return [];
    }
    const step = Math.floor(this.localCityList.length / FOUND_CITIES_AMOUNT) || 1;
    const result: TCity[] = [];
    const sortedCities = this.localCityList.sort(UtilsHelper.sortByNameField);
    for (let i = 0; i < sortedCities.length; i += step) {
      if (!sortedCities[i]) {
        break;
      }
      result.push(sortedCities[i]);
    }
    return result;
  }

  public onCountrySelectChange(event: Event): void {
    const newCountryId: number = (event && event.target && parseInt((event.target as HTMLSelectElement).value, 10)) || 0;
    this.selectedCountry = newCountryId ? this.countries.find(country => country && country.id === newCountryId) : null;
    this.selectCountry.blur();
    this.updateStatesAndCities();
    this.emitResult();
  }

  public async updateStatesAndCities(): Promise<void> {
    this.selectedCity = null;
    this.localCityList = [];
    this.citySearchString = '';
    await this.requestStates(this.selectedCountryId);
    await this.requestCities(this.selectedCountryId);
    this.localCityList = _cloneDeep(this.cities);
  }

  public onCitySearchResultClick(city: TCity): void {
    this.selectedCity = city;
    this.emitResult();
  }

  public emitResult(): void {
    const payload: TEventLocationChoicePayload = {
      country: this.selectedCountry,
      city: this.selectedCity,
    };
    this.$emit('choice', payload);
  }

  public getCityItemText(city: TCity): string {
    return [
      city.name,
      this.getStateDisplayNameByCity(city) || null,
    ]
      .filter(x => x)
      .join(', ');
  }

  public getStateDisplayNameByCity(city: TCity): string {
    const state = this.states.find(state => state.id === city.state_id && state.country_id === city.country_id);
    const result: string = (state && (this.isStateISO2Needed ? state.iso2 : state.name));
    return result ? `<span class="region">${result}</span>` : '';
  }

  public getCityItemClasses(city: TCity): {[key: string]: boolean } {
    const result: {[key: string]: boolean } = {};
    result['item-current'] = city && city.id === this.selectedCityId;
    return result;
  }

  public onCitySelectClick(): void {
    this.isCityFiltererVisible = !this.isCityFiltererVisible;
    if (!this.isCityFiltererVisible) {
      return;
    }

    this.citySearchString = this.selectedCityName || '';
    this.$nextTick(() => {
      this.cityFiltererInput.focus();
    });
  }

  public onCityFiltererClickOutside(e: MouseEvent): void {
    if ((e.target as HTMLElement).classList.contains('current-city-name')) {
      return;
    }
    if (this.isCityFiltererVisible) {
      this.cityFiltererInput.blur();
      this.isCityFiltererVisible = false;
    }
  }
}
