import { render, staticRenderFns } from "./edit-event.html?vue&type=template&id=5b71b3be&scoped=true&"
import script from "./edit-event.vue?vue&type=script&lang=ts&"
export * from "./edit-event.vue?vue&type=script&lang=ts&"
import style0 from "./edit-event.scss?vue&type=style&index=0&id=5b71b3be&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b71b3be",
  null
  
)

export default component.exports