


import {Component, Vue, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {mapState} from 'vuex';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {email, url} from 'vuelidate/lib/validators';
import ValidationHelper from '@/_helpers/validation.helper';
import EventLanguageSelector from '@/_modules/events/components/event-language-selector/event-language-selector.vue';
import {startGoogleMaps} from '@/services/googleMaps';
import EventLocation, {TEventLocationChoicePayload} from '@/_components/event-location/event-location.vue';
import {EventAccessType, TCategory, TEvent} from '@/_types/event.type';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import {TFile} from '@/_types/file.type';
import {TCountry} from '@/_types/country.type';
import {TCity} from '@/_types/city.type';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import timezone from '@/_modules/events/components/timezones.json';
import {TTimezoneInfo} from '@/_types/timezone-info.type';
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import {TEventSettings} from '@/_types/event-settings.type';

type TFormData = {
  title?: string;
  descr?: string;
  image?: string;
  date_start: Date;
  date_end: Date;
  url?: string;
  buy_ticket_url?: string;
  buy_booth_url?: string;
  photos?: TFile[];
  address?: string;
  country_id?: number;
  city_id?: number;
  agendas?: string;
  org_name?: string;
  org_phone?: string;
  org_email?: string;
  org_logo?: string;
  access_type?: EventAccessType;
  event_type_id?: number;
  categories?: TCategory[];
  is_private?: boolean;
  country_name?: string;
  city_name?: string;
  location?: string;
  longitude?: string;
  latitude?: string;
  languages?: string[];
  time_region: string;
}

@Component({
  components: {
    EventLanguageSelector,
    EventLocation,
    FormFileUploader,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
    VueTelInput,
    ErrorInfo
  },
  computed: {
    ...mapState('eventStore', ['categoryList', 'typeList']), // TODO: move to _eventStore and remove old eventStore usage
  },
})

export default class EditEvent extends Vue {
  @Getter('_eventStore/eventError') eventError: ApiErrorResponseData;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TFormData> = {
    formData: {
      org_email: {
        email
      },
      org_phone: {
        isValidPhoneNumber(org_phone: string): boolean {
          return ValidationHelper.isValidPhoneNumber(org_phone);
        },
        isValidPhoneNumberLength(org_phone: string): boolean {
          return ValidationHelper.isValidPhoneNumberLength(org_phone);
        }
      },
      url: {
        url
      },
      buy_ticket_url: {
        url
      },
      buy_booth_url: {
        url
      },
      country_id: {
        isCountryIdValid(countryId: number): boolean {
          return !!countryId;
        },
      },
      city_id: {
        isCityIdValid(cityId: number): boolean {
          return !!cityId;
        },
      },
    },
  }

  public languages: string[] = [];
  public image: TFile = null;
  public orgLogo: TFile = null;
  public photos: TFile[] = [];
  public accessSwitcher: boolean = false;
  public phoneNum: string = '';
  public imageError: string = '';
  public isImageLoading: boolean = false;
  public logoError: string = '';
  public isLogoLoading: boolean = false;
  public photosError: string = '';
  public isPhotosLoading: boolean = false;
  public isQuickRegisterPopoverVisible: boolean = false;
  public imageErrorData: ApiErrorResponseData = null;
  public logoErrorData: ApiErrorResponseData = null;
  public photosErrorData: ApiErrorResponseData = null;
  public countryAndCityError: string = '';

  public get accessTypeAccessor(): EventAccessType {
    if (this.accessSwitcher) {
      return EventAccessType.FREE;
    }
    return EventAccessType.REGISTER;
  }

  public get country(): TCountry | void {
    if (this.event && this.event.country) {
      return this.event.country;
    }
    return null;
  }

  public get city(): TCity | void {
    if (this.event && this.event.city) {
      return this.event.city;
    }
    return null;
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId) : null;
  }

  @Watch('accessSwitcher')
  private onAccessSwitcherChange(): void {
    this.formData.access_type = this.accessTypeAccessor;
  }

  @Watch('image', {deep: true})
  private onImageChange(): void {
    this.formData.image = this.image.url;
  }

  @Watch('formData.org_phone', {immediate: true})
  private onPhoneChange(): void {
    // const phoneInput = this.$refs.phoneInput;
    // phoneInput.$children[0].value = this.formData.org_phone;
    const phone = this.formData.org_phone;
    this.formData.org_phone = '';
    this.formData.org_phone = phone;
  }

  @Watch('orgLogo', {deep: true})
  private onOrgLogoChange(): void {
    this.formData.org_logo = this.orgLogo.url;
  }

  @Watch('photos', { deep: true })
  private onPhotosChange(): void {
    this.formData.photos = this.photos;
  }

  @Watch('event', {immediate: true, deep: true})
  private onEventInfoChange(): void {
    if (!this.event) { return; }

    this.formData.title = this.event.title;
    this.formData.descr = this.event.descr;
    this.formData.image = this.event.image;
    this.formData.date_start = new Date(this.event.date_start);
    this.formData.date_end = new Date(this.event.date_end);
    this.formData.url = this.event.url;
    this.formData.buy_ticket_url = this.event.buy_ticket_url;
    this.formData.buy_booth_url = this.event.buy_booth_url;
    this.formData.photos = (this.event.photos || []).map(item => {
      return Object.assign({}, item, {
        url: item
      });
    });
    this.formData.address = this.event.address;
    this.formData.city_id = (this.event.city && this.event.city.id) || null;
    this.formData.country_id = (this.event.country && this.event.country.id) || null;
    this.formData.country_name = this.event.map.country_name;
    this.formData.city_name = this.event.map.city_name;
    this.formData.location = this.event.map.location;
    this.formData.longitude = this.event.map.longitude;
    this.formData.latitude = this.event.map.latitude;

    this.formData.agendas = this.event.agendas;
    this.formData.org_name = this.event.org_name;
    this.formData.org_phone = this.event.org_phone;
    this.formData.org_email = this.event.org_email;
    this.formData.org_logo = this.event.org_logo;
    this.accessSwitcher = this.event.access_type === 'free';
    this.formData.access_type = this.accessTypeAccessor;
    this.formData.event_type_id = this.event.event_type.id;
    this.formData.is_private = this.event.is_private;
    this.formData.categories = (this.event.categories || []).map(item => {
      return item;
    });

    this.formData.time_region = this.event.time_region;

    this.photos = this.formData.photos;

    this.formData.time_region = this.event.time_region;

    for (let i = 0; i < timezone.length; i++) {
      if (timezone[i].utc.indexOf(this.formData.time_region) > -1) {
        this.timezoneLocation = timezone[i];
      }
    }
    this.phoneNum = this.formData.org_phone;
  }

  @Watch('timezoneLocation', {immediate: true})
  private onTimezoneLocationChanged(): void {
    if (this.timezoneLocation && !this.timezoneLocation.utc) { return; }
    this.formData.time_region = this.timezoneLocation.utc[0];
  }

  public created(): void {
    this.$store.dispatch('eventStore/eventType');
  }

  public formData: TFormData = {
    title: '',
    descr: '',
    image: null,
    date_start: null,
    date_end: null,
    url: '',
    buy_ticket_url: '',
    buy_booth_url: '',
    photos: [],
    address: '',
    country_id: null,
    city_id: null,
    agendas: '',
    org_name: '',
    org_phone: '',
    org_email: '',
    org_logo: null,
    access_type: null,
    event_type_id: null,
    categories: [],
    is_private: true,
    country_name: '',
    city_name: '',
    location: '',
    longitude: '',
    latitude: '',
    time_region: ''
  };

  public endDateMinimum: Date = new Date(); // TODO: check and remove unused
  public isShowStartDate: boolean = false; // TODO: check and remove unused
  public isShowEndDate: boolean = false; // TODO: check and remove unused
  public isShowTimezoneLocation: boolean = false; // TODO: check and remove unused
  public isShowEventType: boolean = false; // TODO: check and remove unused
  public isMultipleLanguages: boolean = false; // TODO: check and remove unused
  public isSendSuccess: boolean = false;
  public updatedHintText: string = '';
  public timezone: TTimezoneInfo[] = timezone;
  public timezoneLocation: TTimezoneInfo = {} as TTimezoneInfo;

  public onEventLocationChoice(payload: TEventLocationChoicePayload): void {
    const isCityInsideCountry: boolean = ((payload && payload.country && payload.country.id) === (payload && payload.city && payload.city.country_id));
    this.countryAndCityError = '';
    if (
      this.formData.country_id !== payload.country.id
      || this.formData.city_id !== payload.city.id
      || !payload.city
      || !isCityInsideCountry
    ) {
      this.formData.address = '';
    }
    if (!isCityInsideCountry) {
      this.formData.city_id = 0;
      this.formData.city_name = '';
    }
    this.formData.country_id = payload.country.id;
    this.formData.country_name = payload.country.name;
    this.formData.city_id = (payload && payload.city && payload.city.id) || 0;
    this.formData.city_name = (payload && payload.city && payload.city.name) || '';
    this.formData.latitude = '0';
    this.formData.longitude = '0';
    this.requestAddressCoords();
  }

  /* Attempt to get Lat Long coords from Google and save them into mapData.map */
  private requestAddressCoords(): void {

    if (process.env.VUE_APP_MAP_PROVIDER !== 'google') {
      return;
    }

    startGoogleMaps().then(() => {
      const request = {
        query: [this.formData.address, this.formData.city_name, this.formData.country_name].filter((x) => x).join(', '),
        fields: ['name', 'geometry']
      };

      // PlacesService requires a DIV element or a map element
      if ((window as any).google) {
        const service = new (window as any).google.maps.places.PlacesService(document.createElement('div'));

        // send request, use response in callback
        service.findPlaceFromQuery(request, (results: any, requestStatus: any) => {
          if (requestStatus === (window as any).google.maps.places.PlacesServiceStatus.OK) {
            this.setCoords(results[0].geometry.location.lat(), results[0].geometry.location.lng());
          }
        });
      }
    });
  }

  private addressToCoords(): void {
    if (this.formData.country_name && this.formData.city_name) {
      this.requestAddressCoords();
    }
  }

  public setCoords(lat: number, lon: number): void {
    this.formData.latitude = lat.toString(10);
    this.formData.longitude = lon.toString(10);
  }

  private deleteHeadImage(): void {
    this.formData.image = '';
  }

  private deleteLogoImage(): void {
    this.formData.org_logo = '';
  }

  private deletePhoto(index: number): void {
    if (!this.formData.photos[index]) {
      return;
    }
    const removedUrl = this.formData.photos[index].url;
    this.formData.photos.splice(index, 1);
    this.photos.forEach((item, index) => {
      if (item.url === removedUrl) {
        this.photos.splice(index, 1);
      }
    });
  }

  private async editEvent(): Promise<void> {
    this.$v.formData.$touch();

    if (!this.formData.country_id || !this.formData.city_id) {
      this.countryAndCityError = this.$tc('editEvent.countryAndCityAreRequired');
      window.scrollTo({top: 0, left: 0, behavior: 'smooth', });
    }

    if (!this.formData || this.$v.formData.$pending || this.$v.formData.$invalid) {
      return;
    }

    const eventId = this.$route.params.eventId;

    const categories: number[] = (this.formData.categories || []).map(cat => cat.id);
    const payload = {
      eventId: eventId,
      formData: Object.assign({}, this.formData, {
        photos: this.formData.photos.map(file => file.url),
        date_start: DateTimeHelper.dateToApiDate(this.formData.date_start),
        date_end: DateTimeHelper.dateToApiDate(this.formData.date_end),
        categories,
      }),
    };

    const result = await this.$store.dispatch('_eventStore/editEvent', payload);
    if (result) {
      this.$v.$reset();
      this.isSendSuccess = true;
      await this.$store.dispatch('_eventStore/refresh');
      this.updatedHintText = this.$tc('eventPage.edit.updatedSuccess');
    } else {
      this.isSendSuccess = false;
      this.updatedHintText = this.$tc('eventPage.edit.updatedError');
    }

    setTimeout(() => {
      this.updatedHintText = '';
    }, 3000);
  }

  public phoneNumberHandler(phone: any, obj: any): void {
    this.formData.org_phone = obj.number;
  }

  private setImageError(errorMessage: string, errorData: ApiErrorResponseData): void {
    this.imageError = errorMessage || '';
    this.imageErrorData = errorData || null;
    setTimeout(() => {
      this.imageError = '';
      this.isImageLoading = false;
    }, 3000);
  }

  private setIsImageLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isImageLoading = true;
      return;
    } else if (value.url) {
      this.isImageLoading = false;
    }
    this.imageErrorData = null;
    this.isImageLoading = false;
  }

  private setLogoError(errorMessage: string, errorData: ApiErrorResponseData): void {
    this.logoError = errorMessage || '';
    this.logoErrorData = errorData || null;
    setTimeout(() => {
      this.logoError = '';
      this.isLogoLoading = false;
    }, 3000);
  }

  private setIsLogoLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isLogoLoading = true;
      return;
    } else if (value.url) {
      this.isLogoLoading = false;
    }
    this.logoErrorData = null;
    this.isLogoLoading = false;
  }

  private setPhotosError(errorMessage: string, errorData: ApiErrorResponseData): void {
    this.photosError = errorMessage || '';
    this.photosErrorData = errorData || null;
    setTimeout(() => {
      this.photosError = '';
      this.isPhotosLoading = false;
    }, 3000);
  }

  private setIsPhotosLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isPhotosLoading = true;
      return;
    } else if (value.url) {
      this.isPhotosLoading = false;
    }
    this.isPhotosLoading = false;
  }

  private preview(): void {
    const routeData = this.$router.resolve({name: 'event-info', params: { eventId: this.$route.params.eventId }});
    window.open(routeData.href, '_blank');
  }

}
